<template>
  <div class="temperature" @click="close">
    <div
      class="day-temperature"
      @click.stop=""
      v-show="temperatureInfo.today_user_temperature.length"
    >
      <div class="title">
        <div class="left">
          <span></span><span>{{ temperatureInfo.name }}&nbsp;当日体温变化</span>
        </div>
        <div class="right">
          <!-- <el-image :src="require('@/assets/image/tl.png')"></el-image> -->
          <div>
            <el-image :src="require('@/assets/image/zc.png')"></el-image>
            <span>正常</span>
          </div>
          <div>
            <el-image :src="require('@/assets/image/fare.png')"></el-image>
            <span>发热</span>
          </div>
        </div>
      </div>
      <div id="day-temperature"></div>
    </div>
    <div
      class="monut-temperature"
      @click.stop=""
      v-show="temperatureInfo.month_user_temperature.length"
    >
      <div class="title">
        <div class="left">
          <span></span><span>{{ temperatureInfo.name }} 历史体温变化</span>
        </div>
        <div class="right">
          <!-- <el-image :src="require('@/assets/image/tl.png')"></el-image> -->
          <div>
            <el-image :src="require('@/assets/image/zc.png')"></el-image>
            <span>正常</span>
          </div>
          <div>
            <el-image :src="require('@/assets/image/fare.png')"></el-image>
            <span>发热</span>
          </div>
        </div>
      </div>
      <div id="monut-temperature"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: ["showTemperature", "temperatureInfo"],
  data() {
    return {
      day_option: {
        tooltip: {
          trigger: "axis",
          formatter(params) {
            return params[0].axisValue + " " + params[0].data + "℃";
          },
        },
        grid: {
          top: "12%",
          right: "3%",
          bottom: "14%",
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            show: true,
          },
        },
        series: {
          data: [],
          type: "line",
          color: "#0083FF",
          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter(param) {
                  let currentValue = new String();
                  currentValue = param.value + "℃";
                  if (param.value >= 37.3) {
                    return "{a|" + currentValue + "}";
                  } else {
                    return currentValue;
                  }
                },
                rich: {
                  a: {
                    color: "#ff0000",
                  },
                },
              },
              color(param) {
                if (param.value >= 37.3) {
                  return "#ff0000";
                } else {
                  return "#0083FF";
                }
              },
            },
          },
        },
      },
      month_option: {
        tooltip: {
          trigger: "axis",
          formatter(params) {
            return params[0].axisValue + " " + params[0].data + "℃";
          },
        },
        grid: {
          top: "12%",
          left: "3%",
          right: "3%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          axisLabel: {
            show: true,
          },
        },
        series: {
          data: [],
          type: "line",
          color: "#0083FF",

          itemStyle: {
            normal: {
              label: {
                show: true,
                formatter(param) {
                  let currentValue = new String();
                  return (currentValue = param.value + "℃");
                },
              },
              color(param) {
                if (param.value >= 37.3) {
                  return "#ff0000";
                } else {
                  return "#0083FF";
                }
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    let temperatureInfo = this.temperatureInfo;
    let today_max_val = temperatureInfo.today_user_temperature.length ? temperatureInfo.today_user_temperature[0].temperature_value : '';
    let today_min_val = temperatureInfo.today_user_temperature.length ? temperatureInfo.today_user_temperature[0].temperature_value : '';
    let month_max_val = temperatureInfo.month_user_temperature.length ? temperatureInfo.month_user_temperature[0].temperature_value : '';
    let month_min_val = temperatureInfo.month_user_temperature.length ? temperatureInfo.month_user_temperature[0].temperature_value : '';
    temperatureInfo.today_user_temperature.map((item) => {
      if(item.temperature_value > today_max_val){
        today_max_val = item.temperature_value
      }
      if(item.temperature_value < today_min_val){
        today_min_val = item.temperature_value
      }
      this.day_option.xAxis.data.push(item.date.time);
      this.day_option.series.data.push(item.temperature_value);
    });
    temperatureInfo.month_user_temperature.map((item) => {
      if(item.temperature_value > month_max_val){
        month_max_val = item.temperature_value
      }
      if(item.temperature_value < month_min_val){
        month_min_val = item.temperature_value
      }
      this.month_option.xAxis.data.push(item.date.time);
      this.month_option.series.data.push(item.temperature_value);
    });
    this.day_option.yAxis.max = today_max_val ;
    this.day_option.yAxis.min = today_min_val == today_max_val ? today_min_val - 0.1 : today_min_val;
    this.month_option.yAxis.max = month_max_val;
    this.month_option.yAxis.min = month_min_val == month_max_val ? month_min_val - 0.1 : month_min_val;
    let chartDom1 = document.getElementById("day-temperature");
    let myChart1 = echarts.init(chartDom1);
    myChart1.setOption(this.day_option);
    let chartDom2 = document.getElementById("monut-temperature");
    let myChart2 = echarts.init(chartDom2);
    myChart2.setOption(this.month_option);
  },
  created() {},
  methods: {
    close() {
      this.$parent.$parent.showTemperature = !1;
    },
  },
};
</script>

<style lang="less" scoped>
.temperature {
  position: fixed;
  z-index: 2000;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  .day-temperature,
  .monut-temperature {
    width: 624px;
    height: 372px;
    background: #fff;
    box-shadow: 0px 0px 40px 3px rgba(203, 225, 247, 0.22);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      padding: 0 20px;
      .left {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        span:nth-child(1) {
          width: 3px;
          height: 16px;
          background: #0083ff;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .el-image {
          width: 17px;
          height: 17px;
        }
        div {
          display: flex;
          align-items: center;
          margin-right: 10px;
          font-size: 14px;
          color: #999;
        }
      }
    }
  }
  .day-temperature #day-temperature {
    width: 100%;
    flex: 1;
  }
  .monut-temperature #monut-temperature {
    width: 100%;
    flex: 1;
  }
  .day-temperature {
    margin-right: 20px;
  }
}
</style>
